import React from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

const TitleText = styled('span')`
  font-family: 'Sora', sans-serif;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 2.5rem;
    line-height: 1;
  }
`;

interface AlternatingTextTitleProps {
  mainText: string | JSX.Element;
  altText: string | JSX.Element;
  alternator: boolean;
  styles?: SxProps<Theme>;
}

type Props = AlternatingTextTitleProps;

const AlternatingTextTitle = ({ alternator, mainText, altText, styles }: Props) => {
  return (
    <Typography variant='h2' gutterBottom sx={styles}>
      <Collapse in={alternator}>
        <TitleText>{mainText}</TitleText>
      </Collapse>
      <Collapse in={!alternator}>
        <span>{altText}</span>
      </Collapse>
    </Typography>
  );
};

export default AlternatingTextTitle;
