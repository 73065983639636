import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import leader1 from 'images/company/leadership/John-Dark-Circle.png';
import leader2 from 'images/company/leadership/Edu-Circle.png';
import leader3 from 'images/company/leadership/Cole-and-Honey-Dark-Circle.png';
import leader4 from 'images/company/leadership/Bree-Dark-Circle.png';
import leader5 from 'images/company/leadership/Jonny-Circle.png';
import leader6 from 'images/company/leadership/Tom-Dark-Circle.png';
import leader7 from 'images/company/leadership/Jessica-Dark-Circle.png';
import leader8 from 'images/company/leadership/Hilary-Circle.png';
import leader9 from 'images/company/leadership/Patrick-Circle.png';

const NoWrap = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const StyledGrid = styled(Grid)`
  width: 50%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
const LeadershipImageContainer = styled('div')`
  height: 4.5rem;
  width: 4.5rem;
`;

const LeadershipTitleContainer = styled(Grid)`
  display: flex;
  align-items: center;

  padding-left: 1.25rem;
`;

const LeaderName = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
  color: black;
`;

const LeaderTitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red500};
`;

const leadershipTeam = [
  {
    image: leader1,
    name: 'John Hart',
    title: 'CEO',
  },
  {
    image: leader2,
    name: 'Eduardo Rihan',
    title: 'Strategic Finance',
  },
  {
    image: leader3,
    name: 'Cole',
    title: 'Strategic Operations',
  },
  {
    image: leader4,
    name: 'Bree Pear',
    title: 'Social + Brand Coordinator',
  },
  {
    image: leader5,
    name: 'Johnny Levin',
    title: 'Creative Operations',
  },
  {
    image: leader6,
    name: 'Tom Demavivas',
    title: 'Graphic + Brand Designer',
  },
  {
    image: leader7,
    name: 'Jessica Skinner',
    title: 'Head Of Sales',
  },
  {
    image: leader8,
    name: 'Hilary Petrokubi',
    title: 'Director of Production',
  },
  {
    image: leader9,
    name: 'Dan Elia',
    title: 'Technical Director',
  },
  {
    image: leader9,
    name: 'Patrick Budde',
    title: 'Lead Audio Engineer',
  },
];

const LeadershipTeam = () => {
  return (
    <StyledGrid container rowGap={4}>
      {leadershipTeam.map((value, index) => {
        return (
          <Grid item xs={12} md={6} lg={6} key={`__${index}__`}>
            <NoWrap>
              <LeadershipImageContainer>
                <img src={value.image} height={'100%'} width={'100%'} alt={'leader-1'} />
              </LeadershipImageContainer>
              <LeadershipTitleContainer>
                <div>
                  <LeaderName>{value.name}</LeaderName>
                  <LeaderTitle>{value.title}</LeaderTitle>
                </div>
              </LeadershipTitleContainer>
            </NoWrap>
          </Grid>
        );
      })}
    </StyledGrid>
  );
};

export default LeadershipTeam;
