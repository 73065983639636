import React from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material';

const TypographyStyledContainer = styled(Typography)`
  max-width: 55.25rem;
  font-weight: normal;
  line-height: 140%;
  margin-bottom: 3.5rem;
  font-size: 1.5rem;
`;

const StyledMainText = styled('span')`
  font-size: 1.25rem;
  font-family: 'Lato', sans-serif;
`;

interface AlternatingTextSectionProps {
  mainText: string | JSX.Element;
  altText: string | JSX.Element;
  alternator: boolean;
  styles?: SxProps<Theme>;
}

type Props = AlternatingTextSectionProps;

const AlternatingTextSection = ({ alternator, mainText, altText, styles }: Props) => {
  return (
    <TypographyStyledContainer variant='h3' sx={styles} gutterBottom>
      <Collapse in={alternator}>
        <StyledMainText>{mainText}</StyledMainText>
      </Collapse>
      <Collapse in={!alternator}>
        <span>{altText}</span>
      </Collapse>
    </TypographyStyledContainer>
  );
};

export default AlternatingTextSection;
