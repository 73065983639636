import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { BuzzwordsContext } from 'context/BuzzwordsContext';
import imgBackground from 'images/about/about-background.png';
import MainContent from 'components/MainContent/MainContent';
import LeadershipTeam from 'components/CompanyPage/LeadershipTeam';
import LeadershipTeamIntro from 'components/CompanyPage/LeadershipTeamIntro';
import AlternatingTextSection from 'components/CompanyPage/AlternatingTextSection';
import AlternatingTextTitle from 'components/CompanyPage/AlternatingTextTitle';
import { Helmet } from 'react-helmet';

const MainContentWrapper = styled('div')`
  background: url(${imgBackground}) no-repeat center;
  background-size: cover;
  color: white;
  padding: 9.5625rem 0.5rem 4.3125rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 3.375rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 3.375rem 1.5rem;
  }
`;

const LeadershipWrapper = styled('div')`
  background: white;
  padding: 6rem 0rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 3.375rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 3.375rem 1.5rem;
  }
`;

const MeetTheTeamWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const SectionHeader = styled(Typography)`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: 'Sora', sans-serif;
  text-transform: uppercase;
`;

const VerboseTriggerButton = styled(Button)`
  transition: width 300s;
  overflow: hidden;
  min-width: 20.3125rem;

  &&& {
    background-color: ${({ theme }) => theme.colors.black};

    &&& {
      background: ${({ theme }) => theme.colors.deepBlue};
      position: relative;
      overflow: hidden;
    }

    &&&:hover {
      background: ${({ theme }) => theme.colors.deepBlue};
    }

    &&&::before {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.colors.black};
      width: 10%;
      height: 25%;
      border-radius: 50%;
      z-index: 1;
      top: 35%;
      left: 45%;
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    }

    &&&:hover::before {
      -webkit-transform: scale(12);
      transform: scale(12);
      -webkit-transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    }

    & > span {
      z-index: 50;
      padding: 0;
    }
  }
`;

const CompanyPage = () => {
  const { buzzwordsOn, toggleBuzzwords } = useContext(BuzzwordsContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#scroll-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    toggleBuzzwords();
  };

  return (
    <MainContent>
      <Helmet>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}/about/`} />
      </Helmet>
      <MainContentWrapper>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={10} sx={{ paddingTop: { xs: '3rem', sm: 0 } }}>
              <AlternatingTextTitle
                alternator={buzzwordsOn}
                mainText={`We’re Revolutionizing… Everything`}
                altText={`Live Bash Is Building Stages For Livestream Performances`}
                styles={{ lineHeight: 1.1 }}
              />
              <AlternatingTextSection
                alternator={buzzwordsOn}
                mainText={`Our website utilizes zeitgeisty innovative technology buzzwords alongside nondescript business jargon to never tell you exactly what we’re doing.`}
                altText={`Live Bash welcomes everyone to book stage time and perform at one of our venues.`}
              />
              <SectionHeader variant='h4' gutterBottom>
                What We Do
              </SectionHeader>
              <AlternatingTextSection
                alternator={buzzwordsOn}
                mainText={`We're democratizing access to state-of-the-art venues by empowering performers to participate in the web 3.0 creator economy thereby fundamentally shifting the paradigm through reimagining fan engagement by building innovative proprietary technology that radically alters consumer content consumption habits and creates new opportunities for all ushering in a new era.`}
                altText={`We broadcast a Livestream where digital collectibles of your performance can be purchased by your fans.`}
                styles={{ fontWeight: '500', fontSize: '18px' }}
              />
              <SectionHeader variant='h4' gutterBottom>
                Who We Are
              </SectionHeader>
              <AlternatingTextSection
                alternator={buzzwordsOn}
                mainText={`We’re just like you: strivers, go-getters, & out-of-the-box thinkers who break down said boxes, wrap them in twine and recycle them in the blue bin. We believe in no days off and unlimited vacation days. But, most importantly, our core values are Support, Transparency, Reliability, and Innovation -- which are totally unique and specific to us`}
                altText={
                  <>
                    Live Bash is an entertainment technology company founded and funded by some of Chicago’s most
                    successful entrepreneurs. We had this idea to make booking stages and making money from performances
                    simpler. <br />
                    <br />
                    Our first stage is launching in Chicago in 2022, followed by Los Angeles soon after.
                  </>
                }
                styles={{ fontWeight: '500', fontSize: '18px' }}
              />
              <VerboseTriggerButton variant={'contained'} color={'primary'} onClick={handleClick}>
                <span>{buzzwordsOn ? 'Remove Buzzwords' : 'Return to Verbose Nomenclature'}</span>
              </VerboseTriggerButton>
            </Grid>
          </Grid>
        </Container>
      </MainContentWrapper>

      <LeadershipWrapper>
        <Container>
          <MeetTheTeamWrapper>
            <LeadershipTeamIntro />
            <LeadershipTeam />
          </MeetTheTeamWrapper>
        </Container>
      </LeadershipWrapper>
    </MainContent>
  );
};

export default CompanyPage;
