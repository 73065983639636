import React from 'react';
import { styled } from '@mui/material/styles';
import { ResponsiveH2, ResponsiveText16 } from 'components/shared';

const AlignSelf = styled('div')`
  ${({ theme }) => theme.breakpoints.down('md')} {
    align-self: start;
  }
`;

const LeadershipTitle = styled(ResponsiveH2)`
  color: black;
  margin-bottom: 0.75rem;
  font-family: 'Poppins';
`;

const ContainedText = styled(ResponsiveText16)`
  color: rgba(18, 17, 39, 0.56);
  margin: 0;
  max-width: 16.5rem;
  font-family: 'Poppins';
  text-transform: none;
`;

const LeadershipTeamIntro = () => {
  return (
    <AlignSelf>
      <LeadershipTitle>Meet our Team</LeadershipTitle>
      <ContainedText>A Small & Tenacious Team Who All Love Donuts.</ContainedText>
    </AlignSelf>
  );
};

export default LeadershipTeamIntro;
